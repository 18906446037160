@use "sass:math";

@function pxToRem($px) {
  @return math.div($px, 16) * 1rem;
}

@for $i from 1 through 100 {
  .opacity-#{$i} {
    opacity: math.div($i, 100) !important;
  }
}
