@import "../assets/scss/abstracts/functions";
@import "../assets/scss/abstracts/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.container {
  display: flex;
  gap: pxToRem(20);
  align-items: flex-start;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.top {
  display: flex;
  gap: pxToRem(20);
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.wrapper {
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}

.body {
  flex-grow: 1;
  background-size: cover;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  min-height: calc(100vh - 80px);
  padding: pxToRem(50) 0;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.header {
  max-width: pxToRem(500);
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  h2 {
    line-height: 125%;
    font-size: pxToRem(36);
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
      line-height: pxToRem(76);
      font-size: pxToRem(64);
      max-width: pxToRem(540);
    }
  }

  p {
    color: $dark60;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    margin-top: 8px;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
      line-height: pxToRem(36);
      font-size: pxToRem(24);
      max-width: pxToRem(540);
    }
  }
}

.label {
  color: $dark;
  font-size: pxToRem(14);
  font-weight: 500;
  line-height: pxToRem(16.8);
  display: inline-block;
  margin-bottom: pxToRem(16);
}

.radio {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.content {
  width: 100%;
  margin-top: 32px;

  .select-form {
    position: relative;
    flex-grow: 1;
    margin-bottom: 21px;
    @include media-breakpoint-up(md) {
      margin-right: 16px;
      max-width: 100%;
    }
  }

  &.branch {
    margin-top: 0;
    margin-bottom: -32px;

    .select-form {
      margin-bottom: -16px;
      @include media-breakpoint-up(md) {
        max-width: 100%;
        margin-right: 16px;
      }
    }
  }

  &.pickup {
    margin-top: 0;
  }

  .cta {
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-up(md) {
      margin-right: 16px;
    }
    button {
      height: 50px;
      box-shadow: 0px 1px 3px rgba(64, 64, 64, 0.15);
      border-radius: 5px;
      @include media-breakpoint-down(md) {
        height: 50px;
        width: 100%;
      }
    }
  }
}

.select {
  input,
  select {
    background-color: #fff !important;
    border: 1px solid #efefef !important;
    border-radius: 8px !important;
    font-size: 1rem;
    font-weight: 400 !important;
    -webkit-appearance: none;

    @include media-breakpoint-up(md) {
      font-size: 14px !important;
    }
  }

  > div {
    :last-child {
      background-color: transparent !important;
    }
  }
}

.input {
  margin-bottom: 0 !important;
}

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
}

.error {
  display: flex;
  align-items: center;
  background: #faeeee;
  border-radius: 5px;
  padding: 13px;
  color: $dark;
  font-size: 14px;
  @include media-breakpoint-up(md) {
    width: calc(100% - 16px);
  }
  svg {
    margin-right: 13px;
  }

  animation: shake 150ms 2 linear;
}

.giftcard-container {
  margin-bottom: 16px;
  display: flex;
  max-width: fit-content;

  justify-content: center;

  @include media-breakpoint-down(md) {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 16px;
  }
}

.giftcard {
  background-color: #f8f8f8;
  height: fit-content;
  padding: 6px;
  padding-right: 12px;
  justify-self: flex-start;

  svg {
    transition: all ease-in-out 300ms;
  }
  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
}

.giftcard-label {
  display: flex;
  align-items: center;
  color: $dark;

  span {
    @include media-breakpoint-down(md) {
      padding: 6px 12px;
      font-size: 8px;
    }
    color: var(--brand-color-foreground);
    background-color: var(--brand-color);
    padding: 11px 30px;
    border-radius: 75px;
    display: flex;
    align-items: center;
  }

  div {
    margin-left: 17px;
    display: flex;
    align-items: center;
    gap: 12px;
    text-align: left;

    @include media-breakpoint-down(md) {
      margin-left: 12px;
    }
  }
}

.desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

@keyframes shake {
  0% {
    transform: translate(3px, 0);
  }
  50% {
    transform: translate(-3px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
